<template>

  <div class="container-fluid p-0 p-md-3">
    <div class="row row-cols-1 row-cols-md-4 row-cols-lg-6 g-0 g-md-4 row-eq-height justify-content-start">
      <template v-for="person in persons" :key="person.id">
        <person-item :person="person" v-if="person" class=""></person-item>
      </template>
    </div>
  </div>

</template>

<script>

import PersonItem from "@/components/PersonItem";
export default {
  data(){
    return{
    }
  },
  components:{
    'person-item': PersonItem
  },
  props: ['persons'],
  
};
</script>
